// @flow
import React from 'react';
import Layout from '../../components/layout';
import Link from '../../components/link';
import type { FrontMatter } from '../../utils/types';
import { withFrontMatter } from '../../components';
import allLinks from '../../data/all-links';

type Props = {
  frontMatter: FrontMatter,
};

class ACA_2018_Skills_Checklist extends React.Component<Props> {
  render() {
    const { frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter} toc>
        <h2>Core Skills Checklist</h2>
        <h3>Logistics and Safety</h3>
        <ul>
          <li>
            Understand the ACA Canyon Rating System and different types of
            canyon terrain, as well as the special issues related to that
            terrain (i.e. swift water canyons, dry and semi-dry canyons, slot
            canyons, pothole escape problems, long committing canyons, permit
            requirements).
          </li>
          <li>
            Using canyon beta; estimate equipment and supply needs, including
            rope lengths, anchor building material, water, food, and bivy gear.
          </li>
          <li>
            Understand leading causes of accidents in canyoneering and
            precautions that can be taken to avoid them.
          </li>
          <li>
            Understand how to find/read/interpret weather reports, cloud
            formations, changing weather patterns.
          </li>
          <li>
            Describe variables that contribute to flash flood risk. Describe
            flash flood warning signs. Describe appropriate responses to an
            impending flash flood.
          </li>
        </ul>
        <h3>Leave No Trace Ethics</h3>
        <ul>
          <li>
            Read the ACA’s{' '}
            <Link to="http://www.canyoneering.net/docs/booklet.pdf">
              Canyons booklet
            </Link>
          </li>
          <li>Understand and apply low impact canyoneering practices.</li>
          <li>
            Understand and apply appropriate methods for human waste disposal.
          </li>
        </ul>
        <h3>Rope and Equipment Care</h3>
        <ul>
          <li>Understand and apply good rope care practices.</li>
          <li>Demonstrate coiling a rope; (a) Alpine coil, (b) Canyon coil.</li>
          <li>Stuff and use a rope bag.</li>
          <li>Understand and apply good equipment care practices.</li>
        </ul>
        <h3>Basic Knots</h3>
        <ul>
          <li>
            Tie and inspect; Figure Eight knot; (a) on a Bight, (b)
            Follow-through.
          </li>
          <li>Tie and inspect; Ring Bend in webbing (aka Water knot).</li>
          <li>Tie and inspect; Clove hitch.</li>
          <li>
            Tie and inspect; Munter hitch (aka Italian hitch, Dynamic hitch).
          </li>
          <li>Tie and inspect; Girth hitch.</li>
          <li>Tie and inspect; 3-wrap Prusik hitch.</li>
          <li>
            Tie and inspect; secure bend to connect two load-bearing ropes for
            rappelling (i.e. Double Fisherman bend, Figure Eight bend).
          </li>
        </ul>
        <h3>Intro to Anchors and Rigging</h3>
        <ul>
          <li>
            Evaluate and rig single-point natural anchors (i.e. tree, arch,
            boulder) using simple webbing wrap, cinching wrap (i.e. wrap 2 pull
            1, wrap 3 pull 2, Girth hitch) and redundant wrap (i.e. Basket with
            Overhand knot at focal point). Explain the pros and cons of each.
          </li>
          <li>
            Evaluate and rig existing fixed artificial anchors (bolts) using the
            acronym EARNEST (Equalized, Angle, Redundant, No Extension, Strong,
            Timely).
          </li>
          <li>
            Set up and use a releasable/contingency system for rappel. Explain
            the hazards of using releasable systems and steps that should be
            taken to avoid accidents.
          </li>
          <li>
            Set up and use blocked rope systems (carabiner blocks) for rappel.
            Explain the hazards of using blocks and steps that should be taken
            to avoid accidents.
          </li>
          <li>Set up and use double rope system (Toss ’n Go) for rappel.</li>
          <li>Set up and use edge protection to protect rope from abrasion.</li>
        </ul>
        <h3>Signals</h3>
        <ul>
          <li>Understand and use proper verbal commands.</li>
          <li>Understand and use proper whistle signals.</li>
          <li>Understand and use proper hand signals.</li>
        </ul>
        <h3>Climbing, Spotting, Belaying, Handlines</h3>
        <ul>
          <li>
            Move on 3rd and 4th class terrain (scrambling - rock climbing
            terrain).
          </li>
          <li>Move on 5th class terrain (rock climbing terrain).</li>
          <li>Demonstrate chimneying, stemming and bridging techniques.</li>
          <li>Spot a climber; climbing up, climbing down.</li>
          <li>
            Understand and use partner capture (climbing down) and partner
            assist (climbing up) techniques for short vertical problems.
          </li>
          <li>
            Belay a climber; climbing up, climbing down, using rope from fixed
            anchor, using rope from human anchor.
          </li>
          <li>Understand and apply appropriate use of a hand line.</li>
          <li>
            Understand and apply appropriate use of a friction hitch safety on a
            hand line.
          </li>
        </ul>
        <h3>Rappelling, Belaying, Self-Rescue</h3>
        <ul>
          <li>
            Rappel; control speed while rappelling, apply brake to stop
            mid-rappel.
          </li>
          <li>
            Rig rappelling device for correct friction on 8mm to 11mm ropes,
            single and double strand, understand the variables that contribute
            to friction requirements.
          </li>
          <li>Add friction while rappelling.</li>
          <li>Lock off while rappelling using leg wrap.</li>
          <li>Lock off rappel device while rappelling.</li>
          <li>Provide bottom belay for rappeller.</li>
          <li>Understand pros and cons of using a self-belay (auto-block).</li>
          <li>
            Demonstrate appropriate rappelling practices to minimize rope
            abrasion.
          </li>
          <li>
            Use rope grab (friction hitch or mechanical ascender) and foot loop
            to remove tension from rappel device to free stuck gear while
            rappelling.
          </li>
        </ul>
        <h3>Swimming, Jumping, Water Slides</h3>
        <ul>
          <li>Swim 50 yards with gear, using floatation if necessary.</li>
          <li>Jumping; identify hazards, correct body position.</li>
          <li>Water slides; identify hazards, correct body position.</li>
        </ul>
        <hr />

        <h2>Aspirant Skills Checklist</h2>
        <h3>Navigation, Map Reading, Canyon Topos</h3>
        <ul>
          <li>
            Read contour lines on a topographic map; identify terrain features
            (hills, saddles, ridges, drainages), determine relative steepness of
            grade, identify potential high ground and escape routes.
          </li>
          <li>Identify relative size of watershed using topo map.</li>
          <li>Orient map with compass and terrain.</li>
          <li>
            Use GPS to determine grid coordinates, mark waypoints and go to
            waypoints.
          </li>
          <li>Plot grid coordinates on a topographic map.</li>
          <li>Prepare and use a canyon topo.</li>
        </ul>
        <h3>Knot Craft</h3>
        <ul>
          <li>
            Tie and inspect; Frost knot in webbing. Make ladder with webbing.
          </li>
          <li>Tie and inspect; Bowline with Yosemite finish.</li>
          <li>
            Tie and inspect inline knots; (i.e. Alpine butterfly, Directional
            Figure Eight).
          </li>
          <li>
            Tie and inspect; (a) Munter hitch, (b) mule hitch or two half
            hitches.
          </li>
          <li>
            Tie and inspect; (a) Valdotain tresse, (b) Asymmetric Prusiks.
          </li>
        </ul>
        <h3>Anchors</h3>
        <ul>
          <li>
            Understand and apply good principles of anchor location; DEAR (Dry,
            Efficient, Accessible, Rope Retrieval)
          </li>
          <li>
            Understand and apply good principles of anchor construction; EARNEST
            (Equalized, Angle, Redundant, No Extension, Strong, Timely)
          </li>
          <li>
            Apply sequencing and friction to utilize otherwise marginal anchors.
          </li>
          <li>
            Construct, evaluate and rig multi-point natural anchors using
            EARNEST.
          </li>
          <li>Construct, evaluate, rig and use dead man anchors.</li>
          <li>Demonstrate how to backup / test a marginal anchor.</li>
        </ul>
        <h3>Rigging</h3>
        <ul>
          <li>
            Set up and use releasable single rope system (contingency),
            including three parts: (1) friction mechanism to allow controlled
            lowering, (2) tie-off that is releasable under tension, and (3)
            identifying risks and setting safety to mitigate those risks.
          </li>
          <li>
            Identify situations that dictate setting rope length to height of
            rappel.
          </li>
          <li>
            Set up and use single rope systems, demonstrate methods for setting
            rope length (i.e. estimating height, lowering first person, setting
            releasable).
          </li>
          <li>
            Understand the pros and cons of double rope systems (toss ’n go).
          </li>
          <li>
            Lower a person, using hands free backup (i.e. friction hitch
            attached to the harness of the person in control of lowering).
          </li>
          <li>
            Set up and use Courtesy Rigging to facilitate trouble-free rope
            retrieval.
          </li>
          <li>Set up and use a safety line to protect a traverse.</li>
        </ul>
        <h3>Belaying</h3>
        <ul>
          <li>Set up and use a Self belay</li>
          <li>Demonstrate proper bottom belay (Firemans belay).</li>
          <li>Set up and use a top-rope belay system.</li>
          <li>
            Demonstrate proper belay commands / verbal communication techniques.
          </li>
        </ul>
        <h3>On Rope Techniques, Companion Rescue</h3>
        <ul>
          <li>Shift Rappeller from rappel line to top rope belay line.</li>
          <li>
            Ascend a fixed rope using friction hitches; single strand, double
            strand.
          </li>
          <li>
            Ascend a fixed rope using mechanical ascenders; single strand.
          </li>
          <li>Transition from rappel to ascend and from ascend to rappel.</li>
          <li>Pass a knot while rappelling. Pass a knot while ascending.</li>
          <li>
            Demonstrate rappelling on a Guided Rappel. Explain safety concerns
            and mitigation.
          </li>
          <li>
            Contact Rescue - Rappel on secured rope to provide assistance to
            person stuck on rappel; provide foot loop to free stuck gear (Not a
            pick-off).
          </li>
        </ul>
        <h3>Swimming, Swift Water</h3>
        <ul>
          <li>Swim 50 yards with gear, without floatation.</li>
          <li>Create and use flotation with gear available.</li>
          <li>Waterfall Rappelling Techniques</li>
          <li>
            Describe stream hazards; foot entrapment, strainers, siphons,
            undercuts, recirculating currents, and appropriate methods for
            dealing with them.
          </li>
        </ul>
        <hr />

        <h2>Canyon Leader 1 Skills Checklist</h2>
        <h3>Anchors</h3>
        <ul>
          <li>
            Construct, evaluate, rig and use dead man anchors using different
            and varied materials.
          </li>
          <li>
            Set up and use human anchors. Explain why and how to properly
            sequence this type of anchor.
          </li>
          <li>Construct, evaluate, and rig Carin anchors.</li>
        </ul>
        <h3>Rigging</h3>
        <ul>
          <li>
            Rig and use static courtesy rigging to facilitate easy rappel starts
            & rope retrieval.
          </li>
          <li>
            Rig and use dynamic courtesy rigging to facilitate easy rappel
            starts & rope retrieval.
          </li>
          <li>
            Set up and use releasable twin rope system (jester, joker),
            including three parts: (1) friction mechanism to allow controlled
            lowering, (2) tie-off that is releasable under tension, and (3)
            identifying risks and setting safety to mitigate those risks.
          </li>
        </ul>
        <h3>Core Rope Work</h3>
        <ul>
          <li>
            Transition from providing a bottom belay to lowering for an
            incapacitated person who is on rappel.
          </li>
          <li>
            Demonstrate use of simple lift-shift systems to help someone on
            rappel free stuck gear.
          </li>
          <li>
            Convert static single rope rigging (e.g. block) to lower. Use hands
            free backup (i.e. friction hitch) when lowering.
          </li>
          <li>
            Tension a guide rope at the bottom using mechanical advantage.
          </li>
          <li>Set up and use a retrievable guided rappel system.</li>
          <li>Protect traverse and rappel with a retrievable safety line.</li>
          <li>Set up and use a counter-weight rappel</li>
        </ul>
        <h3>On Rope Techniques, Companion Rescue</h3>
        <ul>
          <li>
            Demonstrate a 'soft' rappel start; explain when such a start should
            be used.
          </li>
          <li>
            Use drop loop 2:1 to provide lift for rappeller to free stuck gear.
          </li>
          <li>Demonstrate tandem rappel (aka assisted rappel).</li>
        </ul>
        <h3>Participant Coaching / Teaching</h3>
        <ul>
          <li>Rappelling Coaching / Teaching.</li>
          <li>Downclimbing Coaching / Teaching.</li>
        </ul>
        <h3>Problem Solving Scenarios</h3>
        <ul>
          <li>Scenario 1: Shirt / Hair stuck in rappelling device</li>
          <li>
            Scenario 2: Tangled roped and/or rope doesn't touch the ground
          </li>
          <li>Scenario 3: Late start - move group expiditiously</li>
          <li>
            Scenario 4: Evacuate lower leg injury - stable patient; normal
            conditions
          </li>
        </ul>
        <h3>Emergency Response</h3>
        <ul>
          <li>
            Activate EMS system Non-Life threatening (Local SAR; Emergency
            Contacts)
          </li>
          <li>
            Activate EMS system Life Threatening or Time Critical (Local SAR;
            Emergency Contacts)
          </li>
          <li>Presonal Locator Beacon (PLB) instruction / usage</li>
        </ul>
        <h3>Specialized Skills for Class A/B Canyons</h3>
        <p>
          OBJECTIVE: Provide students with the advanced personal and group
          canyoneering skills needed to become effective problem-solvers in
          Class A/B canyons.
        </p>
        <h3>Class A/B Canyons — Core Skills</h3>
        <ul>
          <li>Down Climbing / Stemming ability.</li>
          <li>
            Big Rappels (Free hanging and/or 250'+) - Adding friction
            mid-rappel; communication - radios; hanging packs
          </li>
          <li>Advanced partner captures</li>
        </ul>
        <h3>Class A/B Canyons — Pothole Escape</h3>
        <ul>
          <li>Escape a pothole using partner assist techniques.</li>
          <li>Escape a pothole using counter-weight techniques (pack toss).</li>
          <li>Escape a pothole using aid hooking techniques.</li>
        </ul>
        <h3>Specialized Skills for Class C Canyons</h3>
        <p>
          OBJECTIVE: Provide students with the advanced personal and group
          canyoneering skills needed to become effective problem-solvers in
          Class C canyons.
        </p>
        <h3>Class C Canyons — Core Skills</h3>
        <ul>
          <li>
            Assist swimmer(s) while maintaining separation with sequencing, tag
            lines, throw bags, and/or flotation devices..
          </li>
        </ul>
        <hr />

        <h2>Canyon Leader 2 Skills Checklist</h2>
        <h3>Anchors</h3>
        <ul>
          <li>
            Understand correct placement of fixed artificial anchors (Bolts)
            considering rock type and quality, bolt selection (i.e. Bolt type
            (glue-in vs. expansion, diameter, length).
          </li>
          <li>
            Construct, evaluate and rig chock anchors (chock stones, knot
            chocks). Explain the pros and cons to using knot chocks/stone
            chocks.
          </li>
          <li>Rig and use transient anchors (Pot Shots, Sand Trap, hooks).</li>
        </ul>
        <h3>Core Rope Work</h3>
        <ul>
          <li>
            Demonstrate proper techniques for multi-pitch rappels; security,
            sequencing, rope management.
          </li>
          <li>
            Tension a guide rope; (a) at the top with piggyback system using
            mechanical advantage or counter- weight, (b) at the bottom using
            human anchor.
          </li>
          <li>Set up and use a rappel deviation (aka redirect).</li>
          <li>
            Set up and use combination rappel-lower to avoid multi-pitch rappel
            (utilizing hands free backup)
          </li>
          <li>
            Set up and use retrievable rigging systems for LMAR; (a) retrievable
            webbing, (b) macrame/CEM. Use secure rigging system for all and
            backup/test for LMAR.
          </li>
          <li>
            Set up and use "tail up" retrievable rigging systems (i.e. fiddle
            stick) with backup. Plan for contingencies, such as someone stuck on
            rappel (i.e. top rope belay, rescue rope on standby).
          </li>
        </ul>
        <h3>Rescue Rigging and Rescue</h3>
        <ul>
          <li>
            Demonstrate an understanding of how various rigging and belay
            systems can facilitate or hinder rescue.
          </li>
          <li>
            Demonstrate multiple ways to create a Progress Capture Device (PCD)
            for hauling systems.
          </li>
          <li>
            Demonstrate basic understanding of mechanical advantage systems
            using pulleys for hauling. Understand how various haul systems can
            increase or decrease the load held by the anchor.
          </li>
          <li>
            Demonstrate cut and lower rescue system utilizing rescue rope for
            someone stuck on a rope that is rigged double-strand. Use hands free
            backup (i.e. friction hitch) when lowering.
          </li>
          <li>
            Convert static twin rope rigging (e.g stone knot) to lower. Use
            hands free backup (i.e. friction hitch) when lowering.
          </li>
          <li>
            Convert tail-up rigging (e.g. fiddlestick) to lower utilizing rescue
            rope. Use hands free backup (i.e. friction hitch) when lowering.
          </li>
          <li>
            Perform a pick-off rescue of a person stuck on rope utilizing a
            second rescue rope, including the use of a self-belay.
          </li>
          <li>
            Perform a pick-off rescue of a person stuck on rope without
            utilizing a second rescue rope, descending the subject’s tensioned
            rope. Include the use of a self-belay.
          </li>
          <li>
            Demonstrate from-the-bottom-rescue, ascending the subject’s rope
            from below, passing the person to a position above them, then
            lowering them to the ground.
          </li>
          <li>
            Demonstrate the use of guide line, track line or tag line when
            hauling or lowering to hold subject away from the wall.
          </li>
          <li>
            Demonstrate improvised patient carries that utilize one carrier and
            multiple carriers.
          </li>
        </ul>

        <hr />
        <p>
          <Link to={allLinks.acaSkillsChecklists_20180925}>
            View original PDF
          </Link>
        </p>
      </Layout>
    );
  }
}

export default withFrontMatter('2018-skills-checklist')(
  ACA_2018_Skills_Checklist
);
